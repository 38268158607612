import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, List, ListItem } from "@mui/material";
import { ICustomerConfiguration } from "@interface/ICustomerConfiguration";

type IDebugLinks = {
  customerConfig: ICustomerConfiguration;
};

const DebugLinks: React.FC<IDebugLinks> = ({ customerConfig }) => {
  const { pages } = customerConfig;

  return (
    <Box>
      <List>
        {Object.entries(pages).map(([key, value]) => (
          <ListItem key={key}>
            <Link component={RouterLink} to={value}>
              {key}
            </Link>
          </ListItem>
        ))}
        <ListItem>
          <Link component={RouterLink} to={"/mpi-admin/login"}>
            Login
          </Link>
        </ListItem>
        <ListItem>
          <Link component={RouterLink} to={"/mpi-admin/config"}>
            Config
          </Link>
        </ListItem>
        <ListItem>
          <Link component={RouterLink} to={"/mpi-admin/setup"}>
            Setup
          </Link>
        </ListItem>
      </List>
    </Box>
  );
};

export default DebugLinks;
