import React from "react";
import { Box, Link, List, ListItem } from "@mui/material";
import { ICustomerConfiguration } from "@interface/ICustomerConfiguration";

type IDebugLinks = {
  customerConfig: ICustomerConfiguration;
};

const DebugLinks: React.FC<IDebugLinks> = ({ customerConfig }) => {
  const { pages } = customerConfig;

  return (
    <Box>
      <List>
        {Object.entries(pages).map(([key, value]) => (
          <ListItem key={key}>
            <Link href={value}>{key}</Link>
          </ListItem>
        ))}
        <ListItem>
          <Link href={"/mpi-admin/login"}>Login</Link>
        </ListItem>
        <ListItem>
          <Link href={"/mpi-admin/config"}>Config</Link>
        </ListItem>
        <ListItem>
          <Link href={"/mpi-admin/setup"}>Setup</Link>
        </ListItem>
        <ListItem>
          <Link href={"/testo"}>Testo</Link>
        </ListItem>
      </List>
    </Box>
  );
};

export default DebugLinks;
